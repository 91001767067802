import React, { useContext, useEffect, useState } from "react";
import GetCurrentUser from "../../common/GetCurrentUser";
import { parseJwt, userAvatar } from "../../common/helpers";
import { SocketContext } from "../../Context/socket";

const ConnectedUsers = props => {
    const [state, setState] = useState([]);
    const socket = useContext(SocketContext);
    const user = parseJwt(GetCurrentUser());
    useEffect(() => {
        socket.on("onOnline", data => {
            setState(data.usersOnline.filter(x => x.id !== user.ID));
        });
        socket.on("onLeave", data => {
            setState(data.usersOnline.filter(x => x.id !== user.ID));
        });
    }, [])

    if (state.length === 0) {
        return null;
    }
    return (<ul className="onlineUsers d-none d-lg-block">
        {state.map((item, index) => {
            return (<li key={index} className="mx-1 clickable"
                onClick={() => {
                    props.onPersonalClick(item);
                }}>
                <img src={userAvatar(item.name, 32)} title={item.name} className="img-fluid rounded " alt={item.name} />
            </li>)
        })}
    </ul>);
}
export default ConnectedUsers;