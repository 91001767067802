
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from "react-router-dom";


import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

//App Styles
import "./css/app.css";

import App from "./App";

import '@hookstate/devtools'
ReactDOM.render(
  <Router>

    <App />
  </Router>,
  document.getElementById("root")
);

// <Route path="/" component={Islogin() ? App : Login} exact/>
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
serviceWorkerRegistration.unregister();