//We are settings an initial state here for clarity, probably is not needed

const Settings = {
    App: "BI",
    Name: "BI",

    baseURl: "./",
    //BasePathForAPI: "https://localhost:7193/api/",
    BasePath: "https://api.bi.shearly.com/",
    BasePathForAPI: "https://api.bi.shearly.com/api/",
    GoogleMapsKey: "AIzaSyA5c0WhOqMatPEDmy2RGIBfvhSR9GFOr5k",
    SignalURL: "https://signal2.shearly.com/communicationHub",
    OneSignalApiKey: 'a46b5373-ac0b-4dd0-b92a-fc093cac876e'

};

export default Settings;
