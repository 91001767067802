import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";

import * as yup from "yup";

import TakePicture from "../Picture/TakePicture";
import API from "../../common/API";
import { useFormik } from "formik";
import Settings from "../../common/Settings";
import { parsedUser } from "../../common/GetCurrentUser";

const validationSchema = yup.object({
  latitude: yup.string().required(),
  longitude: yup.string().required(),
  image: yup.string().required(),
  placeId: yup.string().required(),
  note: yup.string().required(),
});

const AddEditVisit = (props) => {
  const user = parsedUser();
  const handleSubmit = async (obj, event) => {
    console.log("values to submit", obj);
    obj.createdId = user.ID;
    let request = await API.postAction("place/AddEditVisit", obj);

    console.log("response from adding", request);
    if (request.status === 200) {
      props.toggle(null, true);
    }
  };
  console.log(props.model);
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: props.model
      ? props.model
      : {
          latitude: null,
          longitude: null,
          id: null,
          image: null,
          note: null,
          placeId: props.place.id,
        },
    validationSchema,
    onSubmit: handleSubmit,
  });
  const [showTakePicture, setShowTakePicture] = useState(false);
  const [imageField, setImageField] = useState(null);
  const toggleTakePicture = (file, field) => {
    setShowTakePicture(!showTakePicture);
    setImageField(field);
    if (file && field === "image") {
      formik.setFieldValue("image", file);
    }
    if (file && field === "image2") {
      formik.setFieldValue("image2", file);
    }
  };
  const GetLocation = () => {
    var geoSuccess = function (position) {
      // Do magic with location

      console.log("the position", position.coords);
      formik.setFieldValue("latitude", position.coords.latitude);
      formik.setFieldValue("longitude", position.coords.longitude);

      //Now we need to request the information from Google
    };
    var geoError = function (error) {
      switch (error.code) {
        case error.TIMEOUT:
          // The user didn't accept the callout
          // showNudgeBanner();
          break;
      }
    };

    navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
  };

  const uploadImg = async (obj, field) => {
    let file = obj.target.files[0];
    console.log(file);
    // Ensure it's an image
    if (file.type.match(/image.*/)) {
      console.log("An image has been loaded");
      let bodyData = new FormData();
      bodyData.append("path", file);
      var request = await API.postAction(`File/Upload`, bodyData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      formik.setFieldValue(field, request.data.response);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      {showTakePicture ? (
        <TakePicture
          toggle={toggleTakePicture}
          show={showTakePicture}
          imageField={imageField}
        />
      ) : null}
      <Modal
        show={props.show}
        onHide={props.toggle}
        size="md"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Row className="mx-0 w-100">
            <Col>
              <h5>Visita</h5>
            </Col>
          </Row>
        </Modal.Header>

        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body className="p-0" style={{ overflow: "auto" }}>
            <Container>
              <Row>
                <Col xs={12} className="my-2">
                  <label>Ubicación</label>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary ms-2 me-2"
                    onClick={() => {
                      GetLocation();
                    }}
                  >
                    Crear pin
                  </button>
                  <span className="d-block text-muted">{`${
                    formik.values.latitude ?? ""
                  }, ${formik.values.longitude ?? ""}`}</span>

                  {formik.errors.latitude ? (
                    <div className="invalid text-sm">
                      {formik.errors.latitude}
                    </div>
                  ) : null}
                </Col>

                <Col className="mt-2" xs={12}>
                  <label>Nota</label>
                  <textarea
                    className="form-control"
                    name="note"
                    value={formik.values.note ?? ""}
                    onChange={formik.handleChange}
                  ></textarea>

                  {formik.errors.note ? (
                    <div className="invalid text-sm">{formik.errors.note}</div>
                  ) : null}
                </Col>

                <Col className="my-2" xs={12}>
                  <input
                    id="imageUploadPrimary"
                    className="form-control d-none"
                    type="file"
                    // multiple
                    capture="camera"
                    accept="image/*"
                    onChange={(obj) => uploadImg(obj, "image")}
                  />
                  <label
                    className="btn btn-secondary btn-sm btn-block"
                    htmlFor="imageUploadPrimary"
                  >
                    <i className="fas fa-camera me-2"></i>
                    Imagen Principal
                  </label>

                  {formik.values.image ? (
                    <img
                      alt=""
                      className="img-thumbnail my-2"
                      style={{ width: "200px" }}
                      id="image"
                      src={`${Settings.BasePath}/files/${formik.values.image}`}
                    />
                  ) : null}
                  {formik.errors.image ? (
                    <div className="invalid text-sm">{formik.errors.image}</div>
                  ) : null}
                  {/* <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={async () => {
                      toggleTakePicture(null, "image");
                    }}
                  >
                    Agregar Imagen
                  </button> */}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Form>
        <Modal.Footer className="p-1">
          <button
            className="btn btn-primary"
            onClick={() => {
              formik.submitForm();
            }}
          >
            Guardar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEditVisit;
