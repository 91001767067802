
import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCalendar, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';

import logoCapital from "../../assets/logos/logoShearlyCap.png";
import logoInitiative from "../../assets/logos/logoShearlyI.png";

import NOTIFICATIONS_DATA from "../../data/notifications";
import { Routes } from "../../routes";
import GetCurrentUser, { parsedUser } from "../../common/GetCurrentUser";
import { userAvatar } from "../../common/helpers";
import { parseJwt } from "../../common/helpers";


import { SocketContext } from "../../Context/socket";


import ConnectedUsers from "./ConnectedUsers";

import { useHistory } from "react-router-dom";
import OrganizationBox from "../Organizations/OrganizationBox";
import Conversations from "../Chat/Conversations";
import API from "../../common/API";


export default (props) => {
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const user = parsedUser();
  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

  const history = useHistory();
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [showShare, setShowShare] = useState(false);


  const toggleShare = () => {
    setShowShare(!showShare);
  }


  const socket = useContext(SocketContext);


  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <image src={image} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };


  const [showChat, setShowChat] = useState(false);
  const toggleChat = () => {

    setShowChat(!showChat)
    if (!showChat === false) {
      setConversationSelected(null);
    }
  }
  const [conversationSelected, setConversationSelected] = useState(null);
  const onPersonalClick = obj => {
    setConversationSelected(obj);
    toggleChat();
  }

  const [showCall, setShowCall] = useState(false);
  const [callData, setCallData] = useState(null);

  const toggleCall = (obj, caller, isVideoCall) => {
    if (obj) {
      setCallData({
        conversation: obj,
        requestedBy: user,
        isVideoCall: isVideoCall
      })
      if (caller === true) {

        socket.invoke("RequestToStartCall", obj.id, user.ID)
      }

    }
    setShowCall(!showCall);
  }

  let chatInterval = null;

  const [chatCount, setChatCount] = useState(0);
  const requestChatCount = async () => {

    var response = await API.getAction("Chat/UnreadCount", "userId=" + user.ID);

    if (response.data.status === "ok") {
      setChatCount(response.data.response)
    }

  }
  useEffect(() => {
    requestChatCount();
    chatInterval = setInterval(() => {
      requestChatCount();
    }, 10000)

    return () => {
      clearInterval(chatInterval)
    }
  }, [])

  return (
    <>
      {showChat && <Conversations show={showChat} toggle={toggleChat} />}
      <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 bg-white shadow-sm">
        <Container>
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">


              <OrganizationBox readOnly />


              <Form className="navbar-search d-none">
                <Form.Group id="topbarSearch">
                  <InputGroup className="input-group-merge search-bar ">
                    <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Search" />
                  </InputGroup>
                </Form.Group>
              </Form>

            </div>
            <Nav className="align-items-center">
              {/* <ConnectedUsers onPersonalClick={onPersonalClick} /> */}

              <button className="btn me-2" onClick={() => toggleChat()}>
                <i className="fa-regular fa-comments"></i>
                {chatCount > 0 && <span className="text-white noty-badge badge bg-danger">{chatCount}</span>}
              </button>
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <img src={userAvatar(user && user.Email, 40)} className="user-avatar rounded" alt="User" />
                    {/* <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">{user && user.Email}</span>
                    </div> */}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item className="fw-bold" onClick={() => history.push(Routes.Profile.path)}>
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> Perfil
                  </Dropdown.Item>


                  <Dropdown.Divider />

                  <Dropdown.Item className="fw-bold"
                    onClick={() => {
                      history.push(Routes.SignIn.path)
                    }}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Cerrar Sesión
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
